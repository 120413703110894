import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>Solexcursion</h1>
            </header>
            <div className="content">
                <p>Randonnée entre mer et bocage<br />
                Les Achards, Vendée (85)</p>

            </div>
        </div>
    </section>
)

export default Banner
