import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import accueil from '../assets/images/accueil2.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/rando19.jpg'
import pic05 from '../assets/images/pic05.jpg'
import pic06 from '../assets/images/pic06.jpg'
import pic07 from '../assets/images/pic07.jpg'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Solexcursion, randonnée entre mer et bocage"
                    meta={[
                        { name: 'description', content: 'Randonnée Solex en Vendée' },
                        { name: 'keywords', content: 'Randonnée, Solex, Vendée, les achards' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${pic04})`}}>
                            <header className="major">
                                <h3>Solexcursion 2019</h3>
                                <p>9 juin 2019</p>
                            </header>
                            <Link to="/rando2019" className="link primary"></Link>
                        </article>
						
                        <article style={{backgroundImage: `url(${pic07})`}}>
                            <header className="major">
                                <h3>Inscriptions ouvertes</h3>
                                <p>du 15 avril au 1er mai</p>
                            </header>
                            <a href="https://forms.gle/X6JZ34cFhgU1QDas8" className="link primary"></a>
                        </article>
                        <article style={{backgroundImage: `url(${accueil})`}}>
                            <header className="major">
                                <h3>Editions passées</h3>
                                <p>Circuits, Photos...</p>
                            </header>
                            <Link to="/AnciennesEditions" className="link primary"></Link>
                        </article>
                        
                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>Excursion à Solex</h2>
                            </header>
                            <p>La solexcursion est une randonnée en solex à travers le paysage vendéen. Depuis presque 20 ans, elle prend son départ des Achards pour une boucle d'une centaine de kilométres. 
                            </p><p>Elle se déroule tous les ans au mois de Juin, depuis 2001.</p>
                            
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex
